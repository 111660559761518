
import twemoji from 'twemoji';

export default {
  name: 'app-ck-content',
  props: ['content'],
  mounted() {
    twemoji.parse(this.$refs.content);
  },
};
